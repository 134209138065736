/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import style from './Onboarding.module.scss';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '@components/Button/Button.js';
// import Logo from '../../components/Header/components/Logo';

const OnboardingPage = ({ data }) => {
    // console.log('data', data);
    const { title, description } = data;
    // paragraph, buttontext, buttonlink, buttonlink1, paragraph1, buttontext1

    const { body } = data;
    const allOnboardingdata = body[0];

    // console.log('allOnboardingdata', allOnboardingdata);

    // const buttonLink = buttonlink?.url
    // const buttonLink1 = buttonlink1?.url

    // console.log('buttonLink', buttonLink);
    // console.log('buttonLink1', buttonLink1);
    // console.log('buttonText', buttontext);
    // console.log('buttonText1', buttontext1);

    const handleClick = (e, buttonLink) => {
        e.preventDefault();
        navigate(`${buttonLink}`);
    };

    // const handleClick1 = (e) => {
    //     // buttonLink1
    //     e.preventDefault();
    //     navigate(`${buttonLink1}`);
    // }

    return (
        <div className={style.boardPage}>
            {/* <Logo img={} /> */}
            <section className={style.hero3}>
                <div className={style.wrapper}>
                    <div className={style.title}>
                        <RichText render={title?.richText} />
                    </div>
                    <div className={style.description}>
                        <RichText render={description?.richText} />
                    </div>
                </div>
                {allOnboardingdata && allOnboardingdata?.items?.map((item, index) => {
                    return (
                        <div key={index} className={style.content} onClick={(e) => handleClick(e, item?.buttonlink?.url)}>
                            <div className={style.allcontent}>
                                <div className={style.paragraph}>
                                    <RichText render={item?.paragraph?.richText} />
                                    <Button
                                        variant={VARIANT.GRADIENT}
                                        isHeader={true}
                                        click={(e) => handleClick(e, item?.buttonlink?.url)}
                                    // to={buttonlink?.url}
                                    >
                                        <RichText render={item?.buttontext?.richText} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {/* <div className={style.content} onClick={handleClick1} >
                    <div className={style.allcontent}>
                        <div className={style.paragraph1}>
                            <RichText render={paragraph1?.richText} />
                            <Button
                                variant={VARIANT.GRADIENT}
                                isHeader={true}
                                click={(e) => handleClick1(e, buttonLink1)}
                            >
                                <RichText render={buttontext1?.richText} />
                            </Button>
                        </div>
                    </div>
                    <div>
                    </div>
                </div> */}
            </section>
        </div>
    );
};

export default OnboardingPage;

OnboardingPage.propTypes = {
    data: PropTypes.object.isRequired,
};
